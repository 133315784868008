import React from 'react';

const Home = () => {
    return (
        <div>
            <img src="http://www.meeko.org/images/pocahontas-meeko.gif" alt="meeko.com" />
        </div>
    )
}

export default Home;