import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Profile from './components/Profile';

const App = () => {

  return (
      <Router>
        <Fragment>
          <Switch>
            <Route exact path="/" component={ Home } />
            <Route exact path="/about" component={ About } />
            <Route exact path='/profile/:name' component={Profile} />
          </Switch>
        </Fragment>
      </Router>
  )
}


export default App;
